import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WINDOW } from '@ng-web-apis/common';
import { captureException } from "@sentry/angular";
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { finalize, first, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { validateEmailList } from '../shared/form-validations';
import { pollWhile } from '../shared/operators';
import { ConnectedAccount, IntervalPlan, Organization, StripeAccount, User } from '../shared/types';
import { Webhook } from '../shared/types/webhook.types';
import { BASE_URL, deleteAllInCollection } from '../shared/utils';
import { OrganizationPlansQuery } from '../state/organization-plans.query';
import { OrganizationPlansService } from '../state/organization-plans.service';
import { OrganizationsQuery } from '../state/organizations.query';
import { OrganizationsService } from '../state/organizations.service';

interface DialogData {
  organizationId: string;
  activeTab?: string;
}

interface RemoveAdminOrganizationRequest {
  organizationId: string;
  adminIds: string[];
}

@Component({
  selector: 'app-organization-settings-dialog',
  templateUrl: './organization-settings-dialog.component.html',
  styleUrls: ['./organization-settings-dialog.component.scss']
})
export class OrganizationSettingsDialogComponent implements OnInit, DialogData {
  activeTab = 'admins';

  organizationId: string;

  organizationPlan: IntervalPlan;
  displayableTabs = ['admins', 'hub', 'account', 'subscription'];

  form: FormGroup = this.fb.group({
    adminIds: this.fb.control([]),
    webhooks: this.fb.array([]),
    // admin invitations
    invitations: this.fb.control('', validateEmailList()),
    // member config
    viewType: this.fb.control('public'),
    memberIds: this.fb.control([]),
    memberInvitations: this.fb.control('', validateEmailList()),
    // org UI metadata
    landing: this.fb.group({
      title: '',
      description: '',
      banner: '',
      logo: '',
      link: '',
    })
  });

  testing = false;

  inviting = false;
  invitingMember$ = new BehaviorSubject(false);

  possibleAdmins: User[];

  stripeAccount$: Observable<StripeAccount>;
  connectedAccount$: Observable<ConnectedAccount>;
  memberUsers$: Observable<User[]>;

  private destroyed$ = new Subject<void>();

  private _connectingStripe$ = new BehaviorSubject(false);

  private _newlyConnectedAccount$ = new BehaviorSubject<StripeAccount>(null);

  private fnGetOrganizationAdmins = this.api.callable<{organizationId: string}, User[]>('get-possible-hosts');
  private fnSendTestWebhook = this.api.callable<{url: string, type: string}, void>('send-test-webhook');
  private fnManageStripeAccount = this.api.callable<{organizationId: string, currentUrl: string}, string>('manage-stripe-account', false);
  private fnGetStripeAccount = this.api.callable<{organizationId: string}, StripeAccount | null>('get-stripe-account', false);
  private fnInviteOrganizationAdmin = this.api.callable<{organizationId: string, emails: string[]}, StripeAccount | null>('invite-organization-admin');
  private fnUpdateOrganizationAdmins = this.api.callable<RemoveAdminOrganizationRequest, boolean>('update-organization-admins');
  private fnInviteOrganizationMember = this.api.callable<{organizationId: string, emails: string[]}, boolean>('invite-organization-member');
  private fnGetOrganizationMembers = this.api.callable<{organizationId: string}, User[]>('get-organization-members');

  constructor(
    private _ref: MatDialogRef<OrganizationSettingsDialogComponent>,
    private api: ApiService,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private query: OrganizationsQuery,
    private snackbar: MatSnackBar,
    private service: OrganizationsService,
    private organizationPlansQuery: OrganizationPlansQuery,
    private organizationPlansService: OrganizationPlansService,
    @Inject(BASE_URL)
    private baseUrl: string,
    @Inject(WINDOW)
    private window: Window,
    @Inject(MAT_DIALOG_DATA)
    data: DialogData
  ) {
    Object.assign(this, data);
  }

  async ngOnInit() {
    if (!this.organizationId) {
      captureException('Tried opening org settings dialog without org id');
      this.close();
      return;
    }

    this.organizationPlansService
      .syncCollection()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();

    this.organizationPlansQuery.selectOrganizationPlan().subscribe((plan) => {
      this.organizationPlan = plan;
    });

    this.stripeAccount$ = this
      .fnGetStripeAccount({organizationId: this.organizationId})
      .pipe(
        shareReplay(1)
      )

    this.connectedAccount$ = combineLatest([
      this._newlyConnectedAccount$,
      this
      .stripeAccount$
    ]).pipe(
        map(([newAcct, loadedAccount]) => {
          const sa = newAcct || loadedAccount;
          if (!sa || !sa.external_accounts || !sa.external_accounts.data?.length) return null;
          const first = sa.external_accounts.data[0];
          return {
            name: first.bank_name || first.brand,
            routingNumber: first.routing_number,
            last4: first.last4,
            type: first.bank_name ? 'bank' : 'card'
          }
        })
      )

    this
      .webhookCollection
      .get()
      .pipe((first()))
      .subscribe((snapshot) => {
        if (!snapshot.size) return;
        const hooks = snapshot.docs.map((d) => d.data());

        const groups = hooks.map((h) => this.fb.group({
          url: h.url,
          events: this.fb.control(h.events || []), // Have to make this explicit, otherwise it thinks second item is validator
        }));
        this.form.setControl('webhooks', this.fb.array(groups));
      });

    const org: Organization = this.query.getEntity(this.organizationId);
    this.form.patchValue({
      adminIds: org.adminIds,
      // note: initialize memberIds to empty array, so firebase does not barf on undefined.
      memberIds: org.memberIds || [],
      // note: here is where we default the UI to public viewType
      viewType: org.viewType === 'membersOnly' ? 'membersOnly' : 'public',
    });
    if (org.landing) {
      this.form.get('landing').patchValue(org.landing)
    }

    this.possibleAdmins = await this
      .fnGetOrganizationAdmins({
        organizationId: this.organizationId,
      })
      .toPromise();

    this.memberUsers$ = this.fnGetOrganizationMembers({organizationId: this.organizationId});
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  isDisplayableTab(
    tab: 'admins' | 'hub' | 'account' | 'subscription'
  ): boolean {
    if (!this.displayableTabs?.length) {
      return true;
    }

    return this.displayableTabs.includes(tab);
  }

  close() {
    this._ref.close();
  }

  get banner(): string {
    return this.form.get(['landing', 'banner'])?.value as string;
  }

  get invitationControl() {
    return this.form.get('invitations');
  }

  get viewTypeControl() {
    return this.form.get('viewType');
  }

  get memberInvitationControl() {
    return this.form.get('memberInvitations');
  }

  setBanner(banner: string) {
    this.form.get('landing').patchValue({banner})
  }

  get logo(): string {
    return this.form.get(['landing', 'logo'])?.value as string;
  }

  setLogo(logo: string) {
    this.form.get('landing').patchValue({logo})
  }

  get webhookCtrls() {
    return this.form.get('webhooks') as FormArray;
  }

  addWebhook() {
    this.webhookCtrls.push(
      this.fb.group({
        events: this.fb.control([
          'event.registration',
          'event.viewership',
          'user.event_viewership',
        ]),
        url: '',
      })
    )
  }

  removeWebhook(idx: number) {
    this.webhookCtrls.removeAt(idx);
  }

  async testWebhook(idx: number) {
    this.testing = true;
    const {url, events} = this.webhookCtrls.at(idx).value;
    const ps: Promise<any>[] = [];
    for (const type of events) {
      ps.push(this.fnSendTestWebhook({url, type}).toPromise());
    }
    Promise
      .all(ps)
      .then(() => {
        if (events.length === 1) {
          this.snackbar.open(`Successfully sent sample "${events[0]}" data to ${url}`, null, {duration: 5000})
        } else {
          this.snackbar.open(`Successfully sent sample data for ${events.length} events to ${url}`, null, {duration: 5000})
        }
      })
      .catch((err) => {
        console.warn('Error sending test webhook data', err);
        this.snackbar.open(`We're sorry, we had some trouble sending test data to that URL. Please check your parameters or try again later if the problem persists`, null, {duration: 5000});
      })
      .finally(() => {
        this.testing = false;
      })
  }

  async save() {
    if (this.invitationControl.value && this.invitationControl.valid) {
      this.snackbar.open('Invitations have not been sent!', null, {
        duration: 3000,
      });
    }

    const {webhooks, adminIds, landing, memberIds, viewType} = this.form.value;
    // tk - cheap trick, just delete and reset
    await deleteAllInCollection(this.webhookCollection);
    const ps: Promise<any>[] = [
      this.service.update(this.organizationId, {landing, memberIds, viewType}),
      this.fnUpdateOrganizationAdmins({
        organizationId: this.organizationId,
        adminIds,
      }).toPromise(),
    ];
    for (const wh of webhooks) {
      ps.push(this.webhookCollection.add(wh))
    }

    await Promise.all(ps);
    this.close()
  }

  invite() {
    const value = this.invitationControl.value?.trim() as string;

    if (!value || this.invitationControl.invalid) {
      return;
    }

    const emails = value
      .split(',')
      .filter((email) => {
        return !!email;
      })
      .map((email) => {
        return email.trim().toLowerCase();
      });

    if (this.invitationControl.valid && value) {
      this.inviting = true;

      this.fnInviteOrganizationAdmin({
        organizationId: this.organizationId,
        emails
      })
        .pipe(
          tap(() => {
            this.invitationControl.setValue('');
            this.snackbar.open('Invitations sent!', null, {
              duration: 3000,
            });
          }),
          finalize(() => {
            this.inviting = false;
          })
        )
        .subscribe();
    }
  }

  inviteMember() {
    const value: string = this.memberInvitationControl.value?.trim() as string;

    if (!value || this.memberInvitationControl.invalid) {
      return;
    }

    const emails: string[] = value
      .split(',')
      .filter((email) => {
        return !!email;
      })
      .map((email) => {
        return email.trim().toLowerCase();
      });

    if (this.memberInvitationControl.valid && value) {
      this.invitingMember$.next(true);

      this.fnInviteOrganizationMember({
        organizationId: this.organizationId,
        emails,
      })
        .pipe(
          finalize(() => {
            this.invitingMember$.next(true);
          })
        )
        .subscribe(
          () => {
            this.memberInvitationControl.setValue('');
            this.snackbar.open('Member invitations sent!', null, {
              duration: 3000,
            });
          },
          (error) => {
            this.snackbar.open('Failed to send member invitations', null, {
              duration: 3000,
            });
            console.error('Failed to send member invitations', error);
            captureException(error);
          }
        );

    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  get connectingStripe$() {
    return this._connectingStripe$.asObservable();
  }

  // [tk - begin polling stripe account for update]
  async manageStripeAccount() {
    this._connectingStripe$.next(true);
    const slug = this.query.getActive().slug;
    const redirect = `${this.baseUrl}/admin/${slug}/stripe-connect`;
    const link = await this
      .fnManageStripeAccount({
        organizationId: this.organizationId,
        currentUrl: redirect,
      })
      .toPromise();

    this.window.open(link);

    this
      .fnGetStripeAccount({organizationId: this.organizationId})
      .pipe(
        takeUntil(this.destroyed$),
        pollWhile(
          5000,
          (acct) => {
            const ready = acct && !!acct.external_accounts?.data?.length;
            return !ready;
          },
          undefined,
          true
        )
      )
      .subscribe((acct) => {
        this._newlyConnectedAccount$.next(acct);
        this._connectingStripe$.next(false);
      })
  }

  private get webhookCollection() {
    return this
      .db
      .collection<Webhook>(`organizations/${this.organizationId}/webhooks`)
  }
}
