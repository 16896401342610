import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { combineQueries } from "@datorama/akita";
import { Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { OrganizationsService } from "src/app/state/organizations.service";
import { OrganizationsStore } from "src/app/state/organizations.store";
import { OrganizationsQuery } from '../state/organizations.query';

// OrganizationMemberGuard implements both CanActivateChild and CanActivate interfaces,
// so we can execute OrganizationMemberGuard and LoggerGuard sequentially, via canActivateChild.

@Injectable({providedIn: 'root'})
export class OrganizationMemberGuard implements CanActivateChild, CanActivate {
  constructor(
    private query: OrganizationsQuery,
    private service: OrganizationsService,
    private store: OrganizationsStore,
    private router: Router,
  ) {
  }

  canActivateItem(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const {organizationSlug} = route.params;

    if (!organizationSlug) {
      return this.router.createUrlTree(['404']);
    }

    return this
      .service
      .syncCollection((ref) => ref.where('slug', '==', organizationSlug))
      .pipe(
        first(),
        map((docChanges) => {
          if (!docChanges?.length) {
            return false;
          }

          const [docChange] = docChanges;

          return docChange.payload.doc.id;
        }),
        mergeMap((id) => {
          if (!id) {
            return of(false);
          }

          this.store.setActive(id as string);

          return combineQueries([
            this.query.selectHost(),
            this.query.selectAdmin(),
            this.query.selectMember(),
          ])
            .pipe(
              first(),
              map(([host, admin, member]) => host || admin || member)
            );
        }),
        map((visible) => {
            if (!visible) {
              return this.router.createUrlTree(['404']);
            }

            return true;
          }
        )
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateItem(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateItem(route, state);
  }
}
